<!--
 * @Author: your name
 * @Date: 2021-09-02 17:56:11
 * @LastEditTime: 2025-03-18 11:53:39
 * @LastEditors: crm
 * @Description: In User Settings Edit
 * @FilePath: 
-->
<template>
  <div class="collection">
    <div class="form-wrapper">
      <el-form
        :inline="true"
        :model="collectionForm"
        :rules="rules"
        class="collection-form"
        label-width="100px"
        label-position="right"
        ref="collectionForm"
      >
        <div class="baseinfo-wrapper page-width">
          <h2 style="margin-bottom: 17px;">订单收款</h2>
          <span>订单信息</span>
          <el-row :gutter="24" style="margin-top: 12px">
            <el-col :span="4">
              <v-text-field
              autocomplete = "off"
                 v-model="collectionForm['orderNo']"
                  label="订单编号"
                  hide-details="auto"
                  disabled
                  outlined
                  :dense="true"
                  :height="32"
              ></v-text-field>
            </el-col>
            <el-col :span="4">
              <v-text-field
              autocomplete = "off"
                v-model="collectionForm['goodsName']"
                label="报名商品"
                hide-details="auto"
                disabled
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-col>
            <el-col :span="4">
              <v-text-field
                autocomplete = "off"
                :value="collectionForm.stuName + '（' + collectionForm.stuMoblie + '）'"
                label="学员信息"
                hide-details="auto"
                disabled
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-col>
            <el-col :span="12" style="height: 54px"></el-col>
            <el-col :span="4">
              <span class="litter-title">应收金额</span>
              <p style="color: red;font-size: 24px">{{collectionForm.amountReceivable || "0.00"}} 元</p>
            </el-col>
            <el-col :span="4">
              <span class="litter-title">已收金额</span>
              <p style="color: red;font-size: 24px">{{collectionForm.amountReceived || "0.00"}} 元</p>
            </el-col>
          </el-row>
        </div>
        <div class="line"></div>
        <div class="paymode-wrapper page-width">
          <h2 style="margin-bottom: 17px;">收款</h2>
        <el-row :gutter="24">
          <el-col>
            <span class="litter-title">收款场景</span>
            <CheckBox 
              ref="checkbox"
              style="margin: 10px 0 10px -10px"  
              :list="[{label: '在线收款', value: 1}, {label: '学员已付款', value: 2}]"
              v-bind:select.sync="collectionForm.type"
            />
          </el-col>


          <!-- 生成收款码 -->
          <div v-if="collectionForm.type === 1">
            <el-col style="margin-top: 14px;">
              <span class="litter-title">支付方式</span>
              <CheckBox 
                style="margin: 10px 0 10px -10px"  
                :list="payTypeList"
                v-bind:select.sync="collectionForm.isReceiveAll"
              />
            </el-col>
            <el-col :span="24">
              <el-form-item  v-if="collectionForm.isReceiveAll === 2">
                <div style="display:flex;margin-top:15px">
                    <v-text-field
                    autocomplete = "off"
                      v-model="collectionForm.preDownPayment"
                      @input="onFirstPartPayChange"
                      @blur="endFirstPartPayChange"
                      label="首付款(元)"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                      :clearable="true"
                    ></v-text-field>
                    <span style="font-size: 12px;color:#999"> 首付款范围: {{ minFirstPayPrice|| 0.00 }} 元 ~</span>
                    <span style="font-size: 12px;color:#999;margin-left:5px">{{ maxFirstPayPrice || 0.00}} 元</span>
              </div>
              </el-form-item>
            </el-col>


            <el-col style="margin-bottom: 12px;margin-top:14px;" v-show="isShowYuE == 'ONLINE_YUE'">
              <span class="litter-title">余额抵扣</span>
              <CheckBox 
                style="margin: 10px 0"  
                :list="[{label: '是', value: 1}]"
                v-bind:select.sync="collectionForm.isBalancePay"
              />
              <span style="font-size: 13px;color: #999;margin-top:14px;display:block;margin-bottom:6px;">待收金额大于 0 时，可使用余额抵扣</span>
            </el-col>
            <div v-if="collectionForm.isBalancePay==1">
              <el-col :span="4">
              <v-text-field
              autocomplete = "off"
                v-model="collectionForm.balanceAccount"
                @blur="getBalanceByMoblie(collectionForm.balanceAccount)"
                @keyup.enter.native="getBalanceByMoblie(collectionForm.stuMoblie)"
                label="学习账号"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                disabled
                :clearable="true"
              ></v-text-field>
            </el-col>
            <el-col :span="4">
              <v-text-field
              autocomplete = "off"
                v-model="collectionForm.balanceName"
                label="学员姓名"
                hide-details="auto"
                disabled
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-col>
            <el-col :span="4">
              <v-text-field
              autocomplete = "off"
                v-model="collectionForm.stuAccountMoney"
                label="账号余额"
                hide-details="auto"
                disabled
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-col>
            <el-col :span="12" style="height: 48px"></el-col>
            <el-col :span="4">
              <v-text-field
              autocomplete = "off"
                :value="collectionForm.balancePay"
                label="本次使用"
                @focus="clearDeafult"
                @input="onBalancePayChange"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-col>
            <el-col :span="20">
              <v-text-field
              autocomplete = "off"
                style="width: 100px;display: inline-block;margin-right: 12px"
                v-model="collectionForm.deductionCode"
                label="验证码"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
              <el-button size="small" v-loading="smsSendStatus"  type="primary" @click="sendSmsCode">{{phoneTipMsg}}</el-button>
            </el-col>
            </div>
        </div>

          <!-- 学员已付款 -->
          <div v-if="collectionForm.type === 2">
            <el-col>
              <span class="litter-title">收款凭证</span>
              <!-- v-for -->
              <el-row :gutter="24" class="voucher-list" v-for="(item, index) in voucherList" :key="index">
                <el-col style="margin-bottom: 12px">
                  <span class="litter-title">{{item.title}}</span>
                </el-col>
                <el-row>
                 <el-col :span="6" style="margin-left:10px">
                  <el-form-item prop="paymentMethod">
                    <!-- 收款方式 -->
                    <v-autocomplete
                     ref="paymentMethod"
                      v-model="item.paymentMethod"
                      :items="collectionTypeList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="收款方式"
                      outlined
                      item-text="name"
                      item-value="id"
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                      @change="selectPaymentMethod(index)"
                    ></v-autocomplete>
                  </el-form-item>
                </el-col>
                <!-- 3是银行 -->
                <el-col :span="6" v-if="item.paymentMethod==8">
                  <!-- 银行账号 -->
                  <el-form-item>
                    <v-autocomplete
                      v-model="item.paymentPlatform"
                      :items="backAccountList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="银行账户"
                      item-text="accountName"
                      item-value="id"
                      outlined
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                      @change="selectBank"
                    ></v-autocomplete>
                  </el-form-item>
                </el-col>
                <!-- 17是贷款 -->
                <el-col :span="6" v-if="item.paymentMethod==7">
                    <!-- 贷款平台 -->
                    <el-form-item>
                      <v-autocomplete
                        v-model="item.paymentPlatform"
                        :items="loanPlatformList"
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="贷款平台"
                        item-text="loanPlatformName"
                        item-value="id"
                        outlined
                        :dense="true"
                        :height="32"
                        :clearable="true"
                        clear-icon="$clear"
                        hide-details="auto"
                        no-data-text="暂无数据"
                        @change="selectpayment"
                      ></v-autocomplete>
                    </el-form-item>
                </el-col>
               </el-row>

                <el-col :span="7">
                  <div class="upload-box">
                    <!-- :http-request="startUpload(index)" -->
                    <el-upload
                        class="avatar-uploader"
                        v-if="!item.scaleImgUrl"
                        :action="imgUpload.host"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess(index)"
                        :data="imgUpload"
                        :before-upload="beforeAvatarUpload(index)"
                        auto-upload
                        :http-request="uploadRequest(index)">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                      <div style="position:relative" v-if="item.scaleImgUrl">
                           <el-image 
                          class="avatar"
                             
                            style="object-fit:contain"
                            :src="item.scaleImgUrl" 
                            :preview-src-list="[item.scaleImgUrl]">
                          </el-image>
                          <div @click="removePic(index)"  style="fontSize:20px;color:#fff;line-height:26px;textAlign:center;position:absolute;border-radius:13px; width:26px;height:26px; right:-10px;top:-10px; backgroundColor:red">x</div>
                      </div>
                     
                  </div>
                </el-col>

                <el-col style="margin-bottom: 12px">
                  <span class="litter-title">凭证信息</span>
                </el-col>
                <!-- 银行 -->
                <el-col :span="24" v-if="item.paymentMethod == 8">
                    <div>流水号<span style="color: red">*</span></div>
                    <el-row>
                      <template>
                        <el-col :span="24">
                          <!-- 凭证流水号 -->
                          <el-form-item style="margin-bottom: 0;width: 100%">
                            <v-autocomplete
                              v-if="isBank && selectNcVoucher == 1"
                              style="width: 120px;display: inline-block"
                              v-model="isSearch"
                              :items="[{name: '搜索选择', id: true}, {name: '手动输入', id: false}]"
                              :menu-props="{ bottom: true, offsetY: true }"
                              item-text="name"
                              item-value="id"
                              outlined
                              :dense="true"
                              :height="32"
                              clear-icon="$clear"
                              hide-details="auto"
                              no-data-text="暂无数据"
                              @change="item.serialNumber=''"
                            ></v-autocomplete>
                            <v-text-field
                              style="display: inline-block;width: 400px"
                              ref="serialNumber"
                              autocomplete = "off"
                              v-model="item.serialNumber"
                              hide-details="auto"
                              outlined
                              :readonly="isSearch && isBank && selectNcVoucher == 1"
                              :dense="true"
                              :height="32"
                              placeholder="银行流水"
                              @click.native="validateThird(item.paymentMethod, index)"
                              @blur="validateSerialNumber(index)"
                            >
                              <v-icon
                                slot="append"
                                color="red"
                                v-if="isSearch && isBank && selectNcVoucher == 1"
                              >
                                search
                              </v-icon>
                            </v-text-field>
                            <div v-if="isBank && item.serialNumber && isSearch" style="color: red;font-size: 12px;line-height: 20px">订单可用金额{{ availablemoney }}元</div>
                          </el-form-item>
                        </el-col>
                        <!-- <el-col :span="18" style="height: 62px">
                          <div class="pz-btn" @click="() => {isSearch =! isSearch;item.serialNumber=''}">切换为"手动填写"银行流水号</div>
                        </el-col> -->
                      </template>
                      <!-- <template v-if="!isSearch || (item.paymentMethod == 8 && !isBank)">
                        <el-col :span="6">
                          <el-form-item>
                            <v-text-field
                             ref="serialNumber"
                             autocomplete = "off"
                              v-model="item.serialNumber"
                              label="银行流水号"
                              hide-details="auto"
                              outlined
                              :dense="true"
                              :height="32"
                              :clearable="true"
                              @blur="validateSerialNumber(index)"
                            ></v-text-field>
                          </el-form-item>
                        </el-col>
                        <el-col :span="18" style="height: 62px" v-if="!isSearch?item.paymentMethod == 8 && isBank && selectNcVoucher == 1:false">
                          <div class="pz-btn" @click="() => {isSearch =! isSearch;item.serialNumber=''}">切换为"搜索选择"银行流水号</div>
                        </el-col>
                      </template> -->
                      <el-col :span="6" style="margin-top: 12px">
                          <el-form-item>
                            <v-text-field
                            autocomplete = "off"
                              v-model="item.money"
                              label="收款金额"
                              hide-details="auto"
                              outlined
                              :dense="true"
                              :height="32"
                              :clearable="true"
                              @input="changeCollectionTotalMoney(index)"
                            ></v-text-field>
                          </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 第三方 -->
                <el-col :span="24" v-if="payType == 5">
                    <el-row>
                      <el-col :span="6" v-if="payType == 5 && (selectNcVoucher != 0 || relateTyStatement != 0)">
                        <!-- 凭证流水号 -->
                        <el-form-item style="margin-bottom: 0">
                          <v-text-field
                            ref="serialNumber"
                            autocomplete = "off"
                            v-model="item.serialNumber"
                            label="第三方订单号"
                            hide-details="auto"
                            outlined
                            readonly
                            :dense="true"
                            :height="32"
                            @click.native="validateThird(item.paymentMethod, index)"
                          >
                            <v-icon
                              slot="append"
                              color="red"
                            >
                              search
                            </v-icon>
                          </v-text-field>
                          <div v-if="payType == 5 && (selectNcVoucher != 0 || relateTyStatement != 0) && item.serialNumber" style="color: red;font-size: 12px;line-height: 20px">第三方订单可用金额{{ availablemoney }}元</div>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6" v-if="selectNcVoucher == 0 && relateTyStatement == 0">
                        <el-form-item>
                          <v-text-field
                            ref="serialNumber"
                            autocomplete = "off"
                            v-model="item.serialNumber"
                            label="第三方订单号"
                            hide-details="auto"
                            outlined
                            :dense="true"
                            :height="32"
                            :clearable="true"
                            @blur="validateSerialNumber(index)"
                          ></v-text-field>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <!-- 收款金额 -->
                          <el-form-item>
                            <v-text-field
                            autocomplete = "off"
                              v-model="item.money"
                              label="收款金额"
                              hide-details="auto"
                              outlined
                              :dense="true"
                              :height="32"
                              :clearable="true"
                              @input="changeCollectionTotalMoney(index)"
                            ></v-text-field>
                          </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <!-- 贷款 -->
                <el-col :span="24" v-if="item.paymentMethod == 7">
                  <el-row>
                     <el-col :span="24">
                  <el-row :gutter="12">
                    <el-col :span="6">
                      <!-- 1、贷款金额 -->
                      <v-text-field
                         autocomplete = "off"
                          v-model="item.money"
                          label="贷款金额(元)"
                          hide-details="auto"
                          outlined
                          :dense="true"
                          :height="32"
                          :clearable="true"
                          @input="inputLoanAmount(index)"
                        ></v-text-field> 
                    </el-col>
                    <el-col :span="3">
                      <!-- 2、期数 -->
                      <v-text-field
                      autocomplete = "off"
                          v-model="item.installmentNumber"
                          label="期数"
                          hide-details="auto"
                          outlined
                          :dense="true"
                          :height="32"
                          :clearable="true"
                          @input="inputInstallmentNumber(index)"
                        ></v-text-field>
                    </el-col>
                    <el-col :span="4">
                      <!-- 3、贴息率 -->
                      <v-text-field
                      autocomplete = "off"
                          v-model="item.companyDiscountRate"
                          label="贴息率(%)"
                          hide-details="auto"
                          outlined
                          :dense="true"
                          :height="32"
                          :clearable="true"
                          @input="inputCompanyDiscountRate(index)"
                        ></v-text-field>
                    </el-col>
                    <el-col :span="4">
                      <!-- 4、贴息 -->
                      <v-text-field
                      autocomplete = "off"
                          v-model="item.companyDiscountMoney"
                          label="贴息(元)"
                          hide-details="auto"
                          outlined
                          :dense="true"
                          :height="32"
                          @input="inputCompanyDiscountMoney(index)"
                          :clearable="true">
                      </v-text-field>
                    </el-col>
                     <el-col :span="4">
                      <!-- 5、保证金 -->
                      <v-text-field
                          v-if="paymentName == '芝士白条' && property == 6"
                          autocomplete = "off"
                          v-model="item.guaranteeMoney"
                          label="保证金（元）"
                          hide-details="auto"
                          outlined
                          :dense="true"
                          :height="32"
                          @input="inputCompanyguaranteeMoney(index)"
                          :clearable="true">
                      </v-text-field>
                    </el-col>
                  </el-row>
                  <el-row :gutter="12">
                      <el-col :span="6" >
                        <!-- 5、到账金额 -->
                        <v-text-field
                        autocomplete = "off"
                            v-model="item.loanReceivedMoney"
                            label="到账金额(元)"
                            hide-details="auto"
                            outlined
                            :dense="true"
                            :height="32"
                            @input="inputLoanReceivedMoney(index)"
                            :clearable="true">
                        </v-text-field>
                      </el-col>
                      <!-- 6、放款日期 -->
                      <el-col :span="6">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="item.loanTime"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                autocomplete = "off"
                                v-model="item.loanTime"
                                label="放款日期"
                                hide-details="auto"
                                outlined
                                :dense="true"
                                :height="32"
                                :clearable="true"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.loanTime" no-title locale="zh-cn">
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">取消</v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu[0].save(item.loanTime)"
                              >
                                确认
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                      </el-col>
                      <!-- 借据号 -->
                      <el-col :span="6" >
                        <v-text-field
                        autocomplete = "off"
                          v-model="item.serialNumber"
                          label="借据号"
                          hide-details="auto"
                          outlined
                          :dense="true"
                          :height="32"
                          :clearable="true">
                      </v-text-field>
                      </el-col>
                  </el-row>
                     </el-col>
                  </el-row>
                </el-col>
                <!-- 其他 -->
                <el-col :span="24" v-if="item.paymentMethod != 7 && item.paymentMethod != 8 && payType != 5">
                    <el-row>
                      <el-col :span="6">
                        <!-- 内部结算 -->
                        <el-form-item>
                          <v-text-field
                            autocomplete = "off"
                            v-model="item.serialNumber"
                            label="特批单号"
                            hide-details="auto"
                            outlined
                            :dense="true"
                            :height="32"
                          >
                          </v-text-field>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <!-- 收款金额 -->
                          <el-form-item>
                            <v-text-field
                            autocomplete = "off"
                              v-model="item.money"
                              label="收款金额"
                              hide-details="auto"
                              outlined
                              :dense="true"
                              :height="32"
                              :clearable="true"
                              @input="changeCollectionTotalMoney(index)"
                            ></v-text-field>
                          </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                
                <el-col :span="12" style="margin-top:10px">
                  <!-- 凭证备注 -->
                  <el-form-item class="memo-form">
                    <v-textarea
                      v-model="item.remark"
                      outlined
                      :height="80"
                      name="input-7-4"
                      label="凭证备注"
                      value=""
                      placeholder="请输入，最多500字"
                    ></v-textarea>
                  </el-form-item>
                </el-col>
                <el-button type="text" class="deleteBtn" @click="delClick(index)">删除</el-button>
              </el-row>
            </el-col>
            <el-col>
              <el-button v-if="!voucherList.length" :disabled="waitPay <= 0" size="small" type="primary" @click="addClick">添加凭证</el-button>
            </el-col>
          </div>
          <el-col style="margin-top:16px;">
            <span class="litter-title">待收金额</span>
            <p style="color: red;font-size: 24px">{{ waitPay }} 元</p>
          </el-col>
          <!-- 支付方式是贷款 -->
          <div v-if="collectionForm.isReceiveAll === 2 && collectionForm.type===1" >
            <el-col :span="12">
              <el-form-item>
                  <v-text-field
                      autocomplete = "off"
                      v-model="needPay"
                      label="首付款待付(元)"
                      hide-details="auto"
                      outlined
                      readonly
                      :dense="true"
                      :height="32"
                    ></v-text-field>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <v-text-field
                  autocomplete = "off"
                  v-model="loadPay"
                  label="贷款金额(元)"
                  hide-details="auto"
                  outlined
                  readonly
                  :dense="true"
                  :height="32"
                ></v-text-field>
                <p v-if="isAllowLoan"  style="color: red;fontSize: 12px">当前贷款金额低于最低贷款金额,无法贷款</p>
              </el-form-item>
            </el-col>
          </div>


          <el-col :span="4" v-if="collectionForm.type==2">
            <v-autocomplete
                v-model="dealQwUserId"
                :items="cashierCheckList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="出纳审核"
                item-text="userName"
                item-value="userId"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
              ></v-autocomplete>
          </el-col>
        </el-row>
        </div> 
        <div class="line"></div>
        <div class="bottom-btn-wrapper">
          <el-button style="width:120px;" size="middle" @click="goOrderManagement" :gap="50">查看订单</el-button>
          <el-button  :disabled="disableSubmit" style="width:120px;" size="middle" type="primary" :loading="loading" @click="onSubmit(collectionForm)">{{ collectionForm.type!=2 ? '收款': '提交审核' }}</el-button>
        </div>
      </el-form>
    </div>
    <remote-share-modal
      ref="mychild"
      :visible="rsModalVisible"
      :createCode="collectionForm"
      :Formmoney="Formmoney"
      :createTime="orderDetails.createTime"
      @onModalClose="onRsModalClose"
      @closeCollection="closeCollection"
      @refreshCollection="refreshCollection"
    />

    <third-party ref="third" @thirdSelect="thirdSelect" />
  </div>
</template>

<script>
import { 
  getTransactionInfoByOrderId,
  getDeptId
} from "api/customer";

import remoteShareModal from "../components/remoteShareModal"
import thirdParty from "./third-party"
import CheckBox from "components/check-box";
import { mapGetters } from "vuex";
import { getSchoolUserCashierVerifiedUser } from '@/api/order/change'
import { addPriceFixed, floatNumberEqual, minusPriceFixed } from "@/utils/price-fixed"
import { formatTimesFileName } from '@/utils/timeDate';
import { mockCashierChecker } from '../../constants';
import { accAdd, accSub } from "@/utils/mathMethod";
import axios from 'axios'
import md5 from 'md5'
import Log from '@/utils/Log';
export default {
  name: "OrderCollection",
  data() {
    return {
      vourcherCount: 1,
      disableSubmit: false,
      imgUpload: {
        //商品名字
        name: '',
        //路径
        key: "",
        OSSAccessKeyId: "",
        success_action_status:200,
        expire : "",
        host : "https://hq-tianyi.hqjy.com",
        policy : '',
        signature : ''
      },
      year: '',
      menu: '',

      //审核人列表
      cashierCheckList: [],

      //凭证列表
      voucherList:[],
      voucherUrl: '/qw_voucher/oss/oss-avatar/upload', //process.env.VUE_APP_VOURCHER_BASE_URL,
      voucherRealUrl:'',
      //审核人列表
      auditList: [],

      //验证码
      smsSendStatus: false,
      phoneCodeStatus: false,
      phoneTipMsg: "",
      smsCodeTimer: null,

      loanPrice: [],
      showloanCheck: false,//是否允许贷款
      loanInfo: {},  //贷款比例信息
      minFirstPayPrice: '', //最低贷款金额
      maxFirstPayPrice: '', //最高贷款金额
      needPay: '', //还需要支付金额
      loanPay: '',  //贷款金额  

      //余额抵扣款
      deduction: 0,
      //余额抵扣学员账号
      deductionMobile: 0,

      dealQwUserId: '',
      collectionForm: {
        //出纳审核人
        dealUserId:'',
        //订单交易id  
        orderId: '',
        //(0全款 1分笔 2贷款)
        isReceiveAll: 0,
        type: 1, //收款场景  1、生成收款码； 2、学员已经付款
        // payMode: "1", //收款方式
        // payChannel: "alipay", //支付方式
        goodsDetail: "",
        showGoodsDetail: "",
        //商品名称
        goodsName: "",
        //待收金额
        amountReceivable: 0,      
        //余额抵扣
        balancePay: '',
        //是否余额抵扣
        isBalancePay: '0',
        isLoan: '0', //是否允许贷款

         //学员账号
        stuMobile: '',
        //学员姓名
        stuName: "",

        //预付款
        preDownPayment: '',
        
        //余额账号
        balanceAccount: '',
        //余额姓名
        balanceName: '',
        //学员余额
        stuAccountMoney: '',
        //验证码
        deductionCode: ''
        
      },
      Formmoney: {},
      orderDetails: {},
      rules: {
        payMode: [{ required: true, trigger: "change", message: "请选择支付方式" }],
        payChannel: [{ required: true, trigger: "change", message: "请选择支付途径" }],
      },

      rsModalVisible: false,
      loading: false,

      //收款方式
      collectionTypeList: [],
      backAccountList: [],
      loanPlatformList: [],
      // 第三方可用金额
      availablemoney: "",
      // payType=5为第三方
      payType: "",
      // NC是否停用
      selectNcVoucher: 1,
      // 天翼是否停用
      relateTyStatement: 1,
      // 是否已对接pos机流水对接
      isBank: false,
      // 银行账号
      bankAccountPk: '',
      // 切换手动
      isSearch: true,
      // 贷款平台名字
      paymentName: '',
      property: null,
      transactionInfo: {},
      isShowYuE:'',
    }
  },
  watch: {
    "collectionForm.type"( newValue ){
      if( this.isNoChange ){
        this.isNoChange = false;
        return;
      }
      
      this.$hqMessageBox({
        title: '提示',
        message: '切换收款场景后会清空之前的数据，确认切换？'
      }).then( ()=>{
          this.clear();

      }).catch( ()=>{
        this.$nextTick(()=>{
          this.isNoChange = true;
          let type = this.collectionForm.type == 1 ? 2: 1;
          this.collectionForm.type = type;
          this.$refs.checkbox.set(type);
        })
      })
      
    },

    "collectionForm.isReceiveAll"(newValue){
      if( newValue === 2 ){
        this.calcNeedPay()
      }
    },

    "collectionForm.isBalancePay"(newValue){
      if( !newValue ){
        this.collectionForm.isBalancePay = "0";
        this.collectionForm.balancePay = "";
      }
    }
  },
  computed: {

    ...mapGetters(["getTyUserInfo", "loginUser"]),

    isAllowLoan(){
      //应收
      let shouldPay =  minusPriceFixed(this.collectionForm.amountReceivable,0);
      return this.loadPay < shouldPay  - this.maxFirstPayPrice 
    },

    //应收金额
    shouldPay(){
      return this.collectionForm.amountReceivable;
    },

    //待支付金额
    waitPay(){

      let price = minusPriceFixed(this.collectionForm.amountReceivable, this.collectionForm.amountReceived, this.collectionForm.balancePay);
      let totalMoney = this.voucherList.reduce( (prev, last)=>{
        return Number(prev) + Number(last.money)
      }, 0 )
      price -= totalMoney;
      if( !price || price < 0 ){
        price = 0;
      }
      return price.toFixed(2);
    },


    //贷款金额
    loadPay(){
      let noLoanPrice = Math.max(addPriceFixed(this.collectionForm.balancePay, this.collectionForm.amountReceived), this.collectionForm.preDownPayment);
      if( isNaN(noLoanPrice) ){
        noLoanPrice = 0;
      }
      //贷款金额
      return  minusPriceFixed(this.shouldPay, noLoanPrice);
    },


    payTypeList(){
      let filterIndex = this.showloanCheck ? 3: 2;
      return [{label: '全款支付', value: 0}, {label: '分笔支付', value: 1}, {label: '贷款', value: 2}].filter( (item,index)=>{
        return index < filterIndex;
      })
    }

  },

   mounted() {
    
    this.collectionForm.orderId = this.$route.query && this.$route.query.orderId;
    this.getFindOrderDetailsByOrderId()
    this.initPhoneTipMsg();
    if( !this.collectionForm.orderId ){
      this.$message.error("订单信息不存在！！！")
    }
    this.getTransactionInfo();
    this.findLoanPlatform();
    
    
  },

  components: {
    remoteShareModal,
    CheckBox,
    thirdParty
  },

  methods: {
    
    
    // 选中第三方订单
    thirdSelect(id, availablemoney, index, statementId) {
      this.availablemoney = availablemoney
      this.voucherList[index].serialNumber = id
      this.voucherList[index].statementId = statementId
    },
    validateThird(id, index) {
      if(id == 8 && (!this.isSearch || !this.isBank || this.selectNcVoucher != 1)) return false
      let thirdType
      if(this.payType == 5) {
        thirdType = this.relateTyStatement?'ty':this.selectNcVoucher?'nc':''
      }
      this.collectionTypeList.map(item => {
        if(item.id == this.voucherList[index].paymentMethod) {
          this.$refs.third.init({clearingformcode: item.ncCodeRef || item.ncCode, paymentMethod: id, bankAccountPk: this.bankAccountPk, thirdType, id: item.id}, index)
        }
      })
    },
    validateSerialNumber( index ){
      let vourcherItem = this.voucherList[index]
      if(vourcherItem.paymentMethod == 8 && !vourcherItem.paymentPlatform) {
        vourcherItem.serialNumber = "";
        this.$message.error('请先选择银行账户')
        return false
      }
      if(vourcherItem.serialNumber.length > 50) {
        this.$message.error('流水号长度不能大于50')
        return false
      }
      this.$fetch("checkSerialNumber", {
        serialNumber:vourcherItem.serialNumber
      }).then( (res={})=>{
        let data = res.data 
        if( data > 0 ){
          this.$message.error(`${vourcherItem.serialNumber}已经存在于数据库中(${vourcherItem.title})`);
          vourcherItem.serialNumber = "";
          this.$refs.serialNumber[index].setValue("")
        } 
      });
    },

    /***
    * 获取成交信息
    */
    getTransactionInfo(){
      const form = {
        orderId: this.collectionForm.orderId
      }
      getTransactionInfoByOrderId(form).then( res=>{
        this.transactionInfo = {};
        if( res.code === 0 ){
          this.transactionInfo = res.result || {};
        }
        this.findSettlementMethod(this.transactionInfo.ncDealSchoolId);//获取线下收款方式
        this.findSettlementMethod_online(this.transactionInfo.ncDealSchoolId)//获取线上收款方式
        console.log('进入了这里');
        this.getBackAccountList( this.transactionInfo.ncDealSchoolId );
        this.setCashierCheck(this.transactionInfo.ncDealSchoolId)
        this._getDeptorderId(this.transactionInfo.crmDealSchoolDeptId)

      })
    },
     // 通过部门拿成交信息
    _getDeptorderId(val) {
      getDeptId(val).then(res => {
        console.log('通过部门拿到成交信息',res);
        this.property = res.result.property
      })
    },


    selectPaymentMethod(index){
      //清空元素中数据
      let vourcherItem = this.voucherList[index];

      this.collectionTypeList.map(item => {
        if(item.id == vourcherItem.paymentMethod) {
          this.payType = item.payType
          this.selectNcVoucher = item.selectNcVoucher
          this.relateTyStatement = item.relateTyStatement
          vourcherItem.settlePayType = item.payType
          vourcherItem.ncCode = item.ncCodeRef || item.ncCode
          vourcherItem.selectNcVoucher = item.selectNcVoucher
        }
      })
      //表示是选择的收款方式
      
      //当前选择的是贷款
      let filterList = this.voucherList.filter(item=>item.paymentMethod==7);
      if( filterList.length > 1 ){
        this.$message.error("收款凭证不允许存在两个贷款，请重新选择");
        this.$refs.paymentMethod[index].setValue("")
        return;
      }

      Object.assign( vourcherItem, {
        //收款平台
        paymentPlatform:'',
        //凭证url
        photoUrl:'',
        scaleImgUrl: '',
        //金额或者贷款金额
        money: '',
        //交易流水号
        serialNumber: '',
        //贷款金额
        // loanAmount: '',
        loanTime:'',
        //分期期数
        installmentNumber: '',
        //贴息率
        companyDiscountRate: '',
        //贴息金额
        companyDiscountMoney: '',
        //到账金额
        loanReceivedMoney: '',
        // 保证金
        guaranteeMoney: ''
      })
    },

    selectBank(value) {
      this.isBank = false
      this.voucherList[0].serialNumber = ""
      if(this.selectNcVoucher != 1) return false
      this.backAccountList.map(item => {
        if(item.id == value) { // item.bankAccountPk
          this.$fetch("getPOSMchidServlet", { accnum: item.bankAccountPk}).then(res => {
            this.isBank = res.mchid?true : false
            this.bankAccountPk = item.bankAccountPk
          }).catch(res => {
            res.msg && this.$message.error(res.msg)
          })
        }
      })
    },
    selectpayment(value) {
      this.loanPlatformList.map((item) => {
        if (item.id == value) {
          console.log('item',item);
          this.paymentName = item.loanPlatformName
        }
      })
      console.log('paymentName', this.paymentName);
    },

    clear(){
      this.voucherList = [];
      this.smsSendStatus = false;
      this.phoneCodeStatus = false;
      this.smsCodeTimer = null;
      this.loanInfo = {};
      this.collectionForm.preDownPayment = this.minFirstPayPrice;
      this.needPay = 0;
      this.loanPay = 0;
      this.collectionForm.dealUserId = '';
      this.dealQwUserId = '';
      this.collectionForm.isReceiveAll = 0;
      this.collectionForm.balancePay = "";
      this.collectionForm.isBalancePay = '0';
      this.isLoan = '0';
    },

    inputLoanAmount( index ){
      let item = this.voucherList[index];
      let itemPrice = Number(item.money);
      if( /\.[\d.]{3,}$/.test(`${itemPrice}`) ){
        this.$message.error("输入金额仅支持两位小数");
          item.money = "";
          return false;
      }

      if( isNaN(itemPrice) ){
        item.money = "";
        this.$message.error("输入金额异常,请重新输入");
        return false;
      } 
    },

    inputInstallmentNumber( index ){
        let item = this.voucherList[index];
         if( /[^0-9]/.test(`${item.installmentNumber}`) ){
            this.$message.error("输入期数仅支持整数");
             item.installmentNumber = "";
             return false;
        }
        let installmentNumber = Number(item.installmentNumber);
       
          if( isNaN(installmentNumber) ){
           item.installmentNumber = "";
            this.$message.error("输入期数仅支持整数");
            return false;
          } 

          if( installmentNumber <= 0 || installmentNumber >= 1000 ){
            item.installmentNumber = "";
            this.$message.error("期数的值仅支持（1~999）");
            return false;
          }
    },

    inputCompanyDiscountRate( index ){
       let item = this.voucherList[index];
      let companyDiscountRate = Number(item.companyDiscountRate);
      if( /\.[\d.]{3,}$/.test(`${companyDiscountRate}`) ){
            this.$message.error("输入金额仅支持两位小数");
             item.companyDiscountRate = "";
             return false;
      }

      if( isNaN(companyDiscountRate) ){
           item.companyDiscountRate = "";
           this.$message.error("输入金额异常,请重新输入");
           return false;
      } 
    },

    inputCompanyguaranteeMoney(index) {
      let item = this.voucherList[index];
      let guaranteeMoney = Number(item.guaranteeMoney);
      if( isNaN(guaranteeMoney) ){
           item.guaranteeMoney = "";
           this.$message.error("输入金额异常,请重新输入");
           return false;
      } 
    },

    inputCompanyDiscountMoney(index){
       let item = this.voucherList[index];
        let companyDiscountMoney = Number(item.companyDiscountMoney);
        if( /\.[\d.]{3,}$/.test(`${companyDiscountMoney}`) ){
            this.$message.error("输入金额仅支持两位小数");
             item.companyDiscountMoney = "";
             return false;
      }

      if( isNaN(companyDiscountMoney) ){
           item.companyDiscountMoney = "";
           this.$message.error("输入金额异常,请重新输入");
           return false;
      } 
    },

    inputLoanReceivedMoney( index ){
      let item = this.voucherList[index];
      let loanReceivedMoney = Number(item.loanReceivedMoney);
      if( /\.[\d.]{3,}$/.test(`${loanReceivedMoney}`) ){
            this.$message.error("输入金额仅支持两位小数");
             item.loanReceivedMoney = "";
             return false;
      }

      if( isNaN(loanReceivedMoney) ){
           item.loanReceivedMoney = "";
           this.$message.error("输入金额异常,请重新输入");
           return false;
      } 
    },

    changeCollectionTotalMoney( index ){
        //先判断是否能转为数字
       if( index !== undefined ){
          let item = this.voucherList[index];
          let itemPrice = Number(item.money);

          if(this.voucherList[index].paymentMethod == 8 && !this.voucherList[index].paymentPlatform) {
            this.$message.error('请先选择银行账号')
            this.$nextTick(() => {
              this.voucherList[index].money = ""
            });
            return false
          }

          if( /\.[\d.]{3,}$/.test(`${item.money}`) ){
            this.$message.error("输入金额仅支持两位小数");
            this.$nextTick(() => {
              item.money = ""
            });
            return false;
          }

          if( isNaN(itemPrice) ){
            this.$nextTick(() => {
              item.money = ""
            });
            this.$message.error("输入金额异常,请重新输入");
            return false;
          }   

          // 如果是第三方单要判断输入金额不能大于第三方单可用金额
          if(this.payType == 5 && (this.selectNcVoucher != 0 || this.relateTyStatement != 0) && Number(this.voucherList[index].money) > Number(this.availablemoney)) {
            this.$message.error("第三方凭证收款金额不可超出第三方订单可用金额")
            this.$nextTick(() => {
              this.voucherList[index].money = ""
            });
            return false
          }
          
          // 如果是第三方单要判断输入金额不能大于第三方单可用金额
          if((this.voucherList[index].paymentMethod == 8 && this.isBank) && Number(this.voucherList[index].money) > Number(this.availablemoney)) {
            this.$message.error("银行流水收款金额不可超出银行流水可用金额")
            this.$nextTick(() => {
              this.voucherList[index].money = ""
            });
            return false
          }
       }

      //再判断是否超过小数位三位
      let totalMoney =  this.voucherList.reduce( (prev, last)=>{
        return prev + Number(last.money);
      }, 0 );
      totalMoney =  Number(totalMoney) || 0;
      // if( (Number(totalMoney) * 1000) > (Number(this.collectionForm.amountReceivable) * 1000 - Number(this.collectionForm.amountReceived) * 1000)){
        if(totalMoney > +accSub(this.collectionForm.amountReceivable, this.collectionForm.amountReceived)) {
        this.$message.error("凭证收款金额总数超过待收金额，无法发起支付")
        this.$nextTick(() => {
          this.voucherList[index].money = ""
        });
        return false;
      }

      return true;
    },
          
    save( index, loadTime ){
       this.menu = false
       let voucherItem = this.voucherList[index];
       voucherItem.loadTime = loadTime     
    },  
  
    async setCashierCheck(schoolNcId){
        const params = {
          schoolNcId //: response.result.ncDealSchoolId
        }
        let res = await  getSchoolUserCashierVerifiedUser(params) || {};
        let data = res.result || mockCashierChecker;
        this.cashierCheckList = data || []
      
    },

    // 添加凭证
    addClick() {
      let title = `凭证${this.vourcherCount++}`
      this.voucherList.push({
        title: title,
        //收款方式
        paymentMethod:'',
        //收款平台
        paymentPlatform:'',
        //凭证url
        photoUrl:'',
        scaleImgUrl: '',
        //金额
        money: '',
        //交易流水号
        serialNumber: '',
        //贷款金额
        // loanAmount: '',
        loanTime:'',
        //分期期数
        installmentNumber: '',
        //贴息率
        companyDiscountRate: '',
        //贴息金额
        companyDiscountMoney: '',
        //到账金额
        loanReceivedMoney: ''
      })
    },
    
    // 删除凭证
    delClick(value) {
      this.voucherList = this.voucherList.filter((item, index) => index !== value)
    },

    //删除凭证图片
    removePic(index) {
      let vourcherItem = this.voucherList[index];
      vourcherItem.scaleImgUrl = "";
    },

    async getFindOrderDetailsByOrderId() {
      
      let { data } = await this.$fetch("thrid_getOrderDetailInfo", {
        orderId: this.collectionForm.orderId
      })
      data = data || {}
      this.usersDetails = data.usersDetails || {}
      this.orderDetails = data.orderDetails || {}
      this.goodsDetails = data.goodsDetails || {}
      this.adminWxPayTypeTradeOrder = data.adminWxPayTypeTradeOrder || {};
      this.orderInoutProjectList = data.orderInoutProjectList || []
      this.specificationPriceVersionVOList = data.specificationPriceVersionVOList || [];
      //应收金额
       let  amountReceivable = isNaN(Number(this.adminWxPayTypeTradeOrder.amountReceivable)) ? 0 : Number(this.adminWxPayTypeTradeOrder.amountReceivable);
       let preferential = 0;//isNaN(Number(this.adminWxPayTypeTradeOrder.preferential)) ? 0 : Number(this.adminWxPayTypeTradeOrder.preferential);
       amountReceivable -= preferential;
       //已收金额
       let  amountReceived =isNaN(Number(this.adminWxPayTypeTradeOrder.amountReceivedAll)) ? 0: Number(this.adminWxPayTypeTradeOrder.amountReceivedAll);
      Object.assign(  this.collectionForm, {
        //用户的id
        userId: this.usersDetails.userId,
        //订单号
        orderNo: this.orderDetails.orderNo,
        // //学员姓名
        stuName: this.usersDetails.name,
        // //学员手机号
        stuMoblie: this.usersDetails.mobile,
        //商品名称
        goodsName: this.goodsDetails.goodsName,
        //应收金额
        amountReceivable: isNaN(amountReceivable) ? 0 : amountReceivable.toFixed(2),
        //已收金额
        amountReceived:isNaN(amountReceived) ? 0: amountReceived.toFixed(2),
        //判断是学历还是财经
        businessId: this.orderDetails.businessId
      })

      this.getBalanceByMoblie(this.usersDetails.mobile)
      this.getPayDetail()

      //判断商品是否可以贷款
      let goodsId = this.goodsDetails.goodsId;  //商品id
      let spPriceIdStr = this.orderDetails.commodityId;
      this.getfindPlatformBySpPriceId( goodsId, spPriceIdStr )
    },

    async getPayDetail() {
      let params = {
        tradeOrderId: this.collectionForm.orderId,
        userId: this.collectionForm.userId,
        schoolId: "4",
        token: "",
      }

      const { data=[] } = await this.$fetch("thrid_queryOrderDetail", params)
      let temp = data[0] || {}
      let enrollInfo = temp.enrollInfo || {};
      Object.assign( this.collectionForm, {
        //商品图像
        classImg: temp.originPath || temp.thumbPath,
        //商品名称
        goodsName: temp.name,
        //商品的班型或者学级等信息
        goodsDetail:  temp.specificationNameAll || temp.specificationNameOne || "",
        //订单号
        orderNo: temp.orderNo,
        //判断是财经还是学历的参数
        businessId: temp.businessId,
        //年级
        academicLevel: enrollInfo.academicLevel,
        //专业
        major: enrollInfo.major
      })
      //用于展示的班型、学级等信息的字段
      this.collectionForm.showGoodsDetail = this.collectionForm.goodsDetail ?  `(${this.collectionForm.goodsDetail})` : "";
    },

    /***
     * 获取支付信息
     */
    async getPayInfo() {
      let params = {
        tradeOrderId: this.collectionForm.orderId,
        token: "",
        userId: this.collectionForm.userId,
        schoolId: "4",
      }

      const { data=[] } = await this.$fetch("thrid_queryOrderDetail", params)
      let tmp = data[0] ||  {};

      // this.Formmoney.waitPayMoney = this.Formmoney.waitPayMoney - //余额抵扣;
      //待付的钱大于0，且没有减少

      if ( tmp.waitPayMoney > 0 && floatNumberEqual(tmp.waitPayMoney,  this.Formmoney.waitPayMoney) ) return
      
      //关闭定时器
       this.clearTimer();

      // 全款支付完成, 且属于签约或者免签的商品
      if( floatNumberEqual(tmp.waitPayMoney, 0) &&  ['20', '21', '23', '24'].indexOf(`${tmp.signContractStatus}`) > -1){
          this.$message.success("订单收款成功");
          //跳转到订单详情
          return this.goOrderManagement()
      }

      //全部支付完成，且没有签约
      if( floatNumberEqual(tmp.waitPayMoney, 0)){
          this.$message.success("订单收款成功");
          //跳转到签约页面
          return this.toCollectionPage();
      }

      //部分支付完成
      this.$message.success( `订单收款成功，本次收款${minusPriceFixed(this.Formmoney.waitPayMoney, tmp.waitPayMoney)}元`)
      //返回上一页
      setTimeout(() => {
          this.$router.go(0)
      }, 2000)
    },

    /***
     * 监控是否在H5端支付完成。
     */
    checkPayComplete() {
      if( this._timer ){
        this.clearTimer();
      }
      this._timer = setInterval(() => {
        this.getPayInfo()
      }, 1500)
    },

    /***
     * 提交收款信息
     */
    onSubmit() {
      
      if (this.loading) return

      //如果之前已经存在贷款，则不允许再贷款
      if( this.orderDetails.installmentType ){
          if(  this.collectionForm.isReceiveAll == 2 ){
            this.$message.error("此订单已经存在贷款，不允许再发起贷款支付")
            return;
          }
          if( this.voucherList.find( item=>item.paymentMethod ==7 ) ){
            this.$message.error("此订单已经存在贷款，不允许再发起贷款凭证")
            return;
          }
      }
      
      //如果是提交凭证
      if( `${this.collectionForm.type}` === '2' ){
        //检查凭证的信息是否有错误
        if(!this.checkVourchValidateCondition()){return;}
        if(!this.changeCollectionTotalMoney()){return;}
        this.$hqMessageBox({
            title: '操作确认',
            message: '确认提交当前收款信息吗?'
          }).then( ()=>{
            this.startCollectionWithVoucher()
          })
        return;
      }

      //检查收款的表单是否有错误
      if( !this.startCollectionValidateCondition() ){
        return;
      }
      this.$hqMessageBox({
            title: '操作确认',
            message: '确认提交当前收款信息吗?'
          }).then( ()=>{
            this.startCollectionWithCode()
          })
    },

    //检查凭证的信息是否有错误
    checkVourchValidateCondition(){

      if(!this.collectionForm.orderId){
        this.$message.error("订单号异常,无法发起收款！");
        return false
      }

       //检测是否存在两个相同的贷款方式
      let  payMenthodIsLoanList = [];
      for( let vourcherItem of this.voucherList ){
        let findItem = payMenthodIsLoanList.find( findItem=>findItem.paymentMethod == vourcherItem.paymentMethod && vourcherItem.paymentMethod==7 );
        if( findItem ){
            this.$message.error(`只允许提交一个贷款凭证，请重新选择(${findItem.title}~${vourcherItem.title})`);
            return;
        }
        payMenthodIsLoanList.push(vourcherItem);
      }

      let serialNumberList = [];
      for( let vourcherItem of this.voucherList ){
        let findItem = serialNumberList.find( findItem=>findItem.serialNumber == vourcherItem.serialNumber )
        if( findItem ){
            this.$message.error(`不允许存在相同的凭证流水号或者借据号(${vourcherItem.title}~${findItem.title})`);
            return;
        }
        serialNumberList.push(vourcherItem);
      }

      
      this.cashierCheckList.map(item => {
        if(this.dealQwUserId == item.userId) {
          this.collectionForm.dealUserId = item.tyUserId
        }
      })

      //检测是否存在两个相同的凭证流水号
      if(!this.dealQwUserId && !this.collectionForm.dealUserId){
        this.$message.error("请选择出纳审核人");
         return;
      }

      if(this.dealQwUserId && !this.collectionForm.dealUserId) {
        this.$message.error("出纳人员未关联天翼账号，请联系运维老师处理")
        return;
      }

      //凭证信息
      let COLLECTION_TYPE_IS_BANK = 8; //付款方式是银行
      let COLLECTION_TYPE_IS_LOAN = 7;  //付款方式是贷款
      if( !this.voucherList.length ){
        this.$message.error("请添加凭证！");
        return;
      }

      return  this.voucherList.every( (item, index)=>{
          index = index+1;
          if( !item.photoUrl ){
              this.$message.error(`请上传凭证(${item.title})`);
              return false;
          }

          if( !item.paymentMethod ){
            this.$message.error(`请选择收款方式(${item.title})`);
              return false;
          }


          if( item.paymentMethod == COLLECTION_TYPE_IS_BANK ){
            //检查必须有银行账号
            if( !item.paymentPlatform ){
              this.$message.error(`银行账号为空(${item.title})`);
              return;
            }
          }

          if(item.remark && item.remark.length > 500) {
            this.$message.error(`凭证备注长度不能大于500(${item.title})`)
            return false
          }

          //2、如果是贷款
          if( item.paymentMethod == COLLECTION_TYPE_IS_LOAN ){
            //贷款金额， 期数， 贴息率， 贴息， 到账金额，放款日期，借据号 都不能为空
            if( !item.money  ){
              this.$message.error(`请输入贷款金额(${item.title})`)
              return false;
            }
            if( !item.paymentPlatform  ){
              this.$message.error(`请选择贷款平台(${item.title})`)
              return false;
            }
            if(!item.installmentNumber){
              this.$message.error(`请输入贷款期数(${item.title})`)
              return false;
            }
            if(!item.companyDiscountRate){
              this.$message.error(`请输入贴息率(${item.title})`)
              return false;
            }

            if(!item.companyDiscountMoney){
              this.$message.error(`请输入贴息(${item.title})`)
              return false;
            }
            if(!item.loanReceivedMoney){
              this.$message.error(`请输入到账金额(${item.title})`)
              return false;
            }
            if(!item.loanTime){
              this.$message.error(`请选择放款日期(${item.title})`)
              return false;
            }
            if( !item.serialNumber  ){
              this.$message.error(`借据号为空(${item.title})`);
                     return false;
            }
          }else {
            if( !item.serialNumber  ){
              this.$message.error(`凭证流水号为空(${item.title})`);
                     return false;
            }
            if( item.serialNumber.length > 50 ){
              this.$message.error(`凭证流水号不能超过50位长度(${item.title})`);
                 return false;
            }
            if( !item.money || item.money <=0 ){
              this.$message.error(`收款金额异常(${item.title})`);
                     return false;
            }
          }
          return true;
      }) 
    },

    //检查收款的表单是否有错误
    startCollectionValidateCondition(  ){
      //如果是全款，分笔，可以使用余额抵扣。
      //如果是贷款，则可以使用余额抵扣以及贷款。
      if(!this.collectionForm.orderId){
        this.$message.error("订单号异常,无法发起收款！");
        return false
      }
      
      /***
       * 贷款需要判断两个值：
       * 1、首付款必须在 最低与最高之间。
       * 2、贷款金额必须大于 最低贷款金额，这个金额是  应收金额-最大贷款比例
       * 
       */
      let preDownPayment = Number(this.collectionForm.preDownPayment);
      preDownPayment = isNaN(preDownPayment) ? 0 : preDownPayment;
      if( this.collectionForm.isReceiveAll == 2 ){
        if( preDownPayment < Number(this.minFirstPayPrice) || preDownPayment > Number(this.maxFirstPayPrice) ){
          this.$message.error(`首付款的输入值超出(${this.minFirstPayPrice}-${this.maxFirstPayPrice})`);
          return false;
        }
      }
    
      
      if(  this.collectionForm.isReceiveAll == 2 &&  this.isAllowLoan ){
        this.$message.error("当前贷款金额低于最低贷款金额,无法贷款");
        return false
      }

      if( this.collectionForm.isBalancePay == 1 ){
        //如果选择了余额抵扣，那么就必须检查学员信息以及抵扣额
        let errorMsg = "";
        if( this.collectionForm.balancePay <= 0 ){
          errorMsg = "余额抵扣必须大于0";

        }
        //余额抵扣学员账号
        if( !this.collectionForm.stuMoblie ){
          errorMsg = "学员账号为空";
        }
        //学员姓名不能为空
        if( !this.collectionForm.stuName ){
          errorMsg = "学员账号为空";
        }
        //余额抵扣验证码
        if( !this.collectionForm.deductionCode){
          errorMsg = "余额抵扣验证码为空";
        }
        
        if( errorMsg ){
          this.$message.error(errorMsg)
        }
        return !errorMsg;  
      }

      return true;

    },

    /***
     * 根据学员已付款的情形来进行收款
     */
    async startCollectionWithVoucher(){

      let params = {
        //订单
        orderId: this.collectionForm.orderId,
        //startUserId 天翼下单人的id
        startUserId: this.loginUser.tyUserId,
        //dealUserId  纳税人id
        dealUserId:this.collectionForm.dealUserId,
        //凭据
        wxPayVoucherEntityList: [...this.voucherList.map( item=>{
           //将空数据的设置为undefined，在提交数据的时候会被过滤，而不会被提交。
            Object.keys(item).forEach( propertyKey => {
             if( item[propertyKey] === "" || item[propertyKey] === null ){
                item[propertyKey] = undefined;
             }
           });

          let findItem = this.collectionTypeList.find( findItem=>findItem.id==item.paymentMethod )
          if( findItem ){
            item.paymentMethod = findItem.id;
          }

           //保证是number类型
           item.money = +item.money 
           return item;
        })]
      }
      try {
        this.loading = true
        await this.$fetch("payVoucher", params);
        this.$message.success("收款订单提交成功")
        //成功跳转到订单详情页面
        this.goOrderManagement();
      } catch(e){
        this.$message.error(e.msg)
      } finally {
        this.loading = false
      }
      
    },


    /***
     * 根据收款码的情景来进行收款
     */
    async startCollectionWithCode(){
      try {
            /***
             * 订单9期的该接口做有调整：
             * orderId：     交易订单id；
             * isReceiveAll：(0全款 1分笔 2贷款)；
             * deduction：   余额抵扣款；
             * deductionMobile： 余额抵扣学员账号；
             * deductionCode：   招生工作台余额抵扣验证码；
             * preDownPayment：  预首付款(贷款必填字段)；
             */
            this.loading = true
            let params = {
              //orderId：     交易订单id；
              orderId: this.collectionForm.orderId,
              //isReceiveAll：(0全款 1分笔 2贷款)；
              isReceiveAll: this.collectionForm.isReceiveAll,
              //余额抵扣款
              deduction: this.collectionForm.balancePay || 0,
              //余额抵扣学员账号
              deductionMobile: this.collectionForm.balanceAccount,
              //招生工作台余额抵扣验证码
              deductionCode: this.collectionForm.deductionCode,
              //预首付款(贷款必填字段)
              preDownPayment: +this.collectionForm.preDownPayment
            }
            if( params.isReceiveAll != 2 ){
              params.preDownPayment = undefined;
            }
            if( params.deduction === 0 ){
              params.deduction = undefined;
              params.deductionMobile = undefined;
              params.deductionCode = undefined;
            }
            
            let { data, code, msg } = await this.$fetch("thrid_getbillLinkInfo", params )
            this.loading = false
            
            if(code === 500) {
              // this.$message.error(msg);
              this.$message({
                type: 'error',
                message: msg,
                duration: 3000
              });
              setTimeout(() => {
                this.$router.go(0)
              }, 3000)
              return
            }
            
            if( code != 200 ){
              this.$message.error(msg);
              return
            }

            //ck提的需求： 生成了收款，则将提交按钮设置为不可用
            this.disableSubmit = true;

            //待收金额为0，则直接跳转到详情或者签约页面
            if( `${data.waitPayMoney}` === '0' ){
              setTimeout(() => {
                this.getPayInfo()
              }, 1500)
              // //已经签约或者免签的商品, 跳转到详情页面
              // if(['20', '21', '23', '24'].indexOf(`${this.usersDetails.signContractStatus}`) > -1){
              //  this.goOrderManagement();
              // }else {
              //   //跳转到签约页面
              //    this.toCollectionPage();
              // } 
              return;
            }

            this.Formmoney = data || {};
            Object.assign( this.Formmoney, this.collectionForm, {
              name: this.collectionForm.goodsName
            })
            this.rsModalVisible = true

            //等下一个runloop再执行。
            this.$nextTick(()=>{
              this.$refs.mychild.crateQrcode()
              //开启一个api的定时请求，用于判断支付信息
              this.checkPayComplete()
            })

          } catch (error) {
            this.loading = false
            this.$message.error(error  && error.msg || "生成海报数据异常，请联系管理员")
          }
    },

    /***
     * 跳转到订单详情
     */
    goOrderManagement() {
      let path = `${this.orderDetails.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      //跳转到订单详情
      this.$router.push( {
        path: path,
        query: {
          orderId: this.collectionForm.orderId,
          status: this.orderDetails.orderStatus
        }
      });
    },

    /***
     * 跳转到收款页面
     */
    toCollectionPage(){
       this.$router.push({
        path: "/aftersale/payComplete",
        query: {
          orderId: this.collectionForm.orderId
        },
      })
    },

    /**
     * 远程分享弹窗关闭回调
     */
    onRsModalClose() {
      this.clearTimer()
      this.rsModalVisible = false
      this.goOrderManagement();
    },

    /**
     * 关闭定时器
     */
    clearTimer() {
      window.clearInterval(this._timer)
      this._timer = null
    },

    /**
     * 结束收款
     */
    closeCollection() {
      this.rsModalVisible = false
    },

    /**
     * 刷新收款码
     */
    refreshCollection() {
      this.onSubmit()
    },

    // 根据手机号获取学员的账户余额
    async getBalanceByMoblie(mobile) {
      this.collectionForm.balanceAccount = mobile
      if (!mobile) {
        return this.$message.error("请输入手机账号")
      }
      const params = { mobile }
      const { data={} } = await this.$fetch("thrid_getBalanceByMoblie", params)
      //学员姓名
      if( data.nickName ){
        this.collectionForm.balanceName = data.nickName;
      }
      //学员余额
      this.collectionForm.stuAccountMoney = '0.00'
      if( data.money ){
        this.collectionForm.stuAccountMoney = data.money;
      }
    },

    /**
     * 清除默认的本次使用
     */
    clearDeafult() {
      if (this.collectionForm.balancePay == 0) {
        this.collectionForm.balancePay = ""
        // this.initPreDownPayment();
        this.calcNeedPay();
      }
    },

    // 老师根据学员账户的余额，填写，这次使用的抵扣输入框改变
    onBalancePayChange(val) {
      
       //保证小数点不能超过2位  
      if( /\.[\d.]{3,}$/.test(`${val}`) ){
         this.$message.error("输入金额仅支持两位小数");
         this.collectionForm.balancePay = "";
         this.calcNeedPay();
         return;
       }    

      if( val === "" ){
        this.collectionForm.balancePay = "";
        this.calcNeedPay();
        return 
      } 

      let balancePay = Number(val);
      //判断余额是否是数字
      if(  isNaN(balancePay) ){
        this.collectionForm.balancePay = "";
        this.$message.error("输入金额只能含有数字以及小数点")
        this.calcNeedPay();
        return 
      } 

      //保证是 Number 类型的数据
      this.collectionForm.balancePay = balancePay;

      //判断是否使用余额
      if ( this.collectionForm.isBalancePay == 0) {
        this.collectionForm.isBalancePay = 0;
        this.collectionForm.balancePay = "";
        this.calcNeedPay();
        return
      }

      /* 
       * this.collectionForm.stuAccountMoney 账户总余额
       * this.collectionForm.balancePay  输入的抵扣余额
       * 余额抵扣不能超过账户总余额
       **/
      if ( balancePay > this.collectionForm.stuAccountMoney ) {
        this.collectionForm.balancePay = ""
        this.calcNeedPay();
        return this.$message.error("余额抵扣金额不能超过账户余额")
      }

      /**
       * 余额抵扣不能高于待收金额
       * this.collectionForm.shouldPay： 应收金额
       * */
      let shouldPay = this.collectionForm.amountReceivable - this.collectionForm.amountReceived;
      if(!isNaN(+shouldPay)){
        shouldPay = +(Number(shouldPay).toFixed(2))
      }
      if( balancePay > shouldPay ){
        this.collectionForm.balancePay = ""
        this.calcNeedPay();
        return this.$message.error("余额抵扣金额不能超过待收金额")
      }
      
      //计算出待收金额
      // this.collectionForm.waitPay = this.collectionForm.shouldPay - this.collectionForm.balancePay;
      this.calcNeedPay();
    },

    async sendSmsCode() {
      //判断手机号

      let stuMobile =   this.collectionForm.balanceAccount || "";
      if ( stuMobile.length < 11 || !stuMobile.startsWith("1")) {
        return this.$message.error("手机账号格式不正确")
      }

      let balancePay = Number(this.collectionForm.balancePay);
      if( balancePay <= 0 ){
        return this.$message.error("余额抵扣必须大于0.00元")
      }
      if( balancePay > this.collectionForm.stuAccountMoney ){
        let stuAccountMoney = Number(this.collectionForm.stuAccountMoney) || 0;
        return this.$message.error(`余额抵扣必须小于最大余额${stuAccountMoney.toFixed(2)}`)
      }

      this.smsSendStatus = true;
      //发送验证码
      await this.$fetch("sendAliSms", {
        phone: stuMobile,
        money: balancePay
      })
      this.smsSendStatus = false;
      //手机验证码状态
      this.phoneCodeStatus = true
      //多少秒之后重发
      this.phoneTipMsg = this.smsCodeInterval + "秒后重发"
      //定时器
      if( this.smsCodeInterval ){
         this.clearSmsTimer();
      }
      this.smsCodeTimer = setInterval(() => {
        if (this.smsCodeInterval <= 1) {
          this.initPhoneTipMsg();
          return
        }
        this.smsCodeInterval--
        this.phoneTipMsg = this.smsCodeInterval + "秒后重发"
      }, 1000)
    },

    initPhoneTipMsg(){
      this.clearSmsTimer()
      this.phoneTipMsg = "获取验证码"
      this.phoneCodeStatus = false
      this.smsCodeInterval = 60
    },

    clearSmsTimer(){
      clearInterval(this.smsCodeTimer);
      this.smsCodeTimer = null;
    },


    /***
    * 判断该商品是否能使用贷款
    */
    async getfindPlatformBySpPriceId(goodsId, spPriceIdStr ) {
      let { data, code } = await this.$fetch("third_getfindPlatformBySpPriceId", {
        goodsId,
        spPriceIdStr
      })
      if (code == 200) {
        this.showloanCheck = data && data.length !== 0;
        this.loanPrice = this.showloanCheck && data;
        let platformId = this.loanPrice  && this.loanPrice.length && this.loanPrice[0].loanPlatformId
        this.clickFinalMoney( goodsId, spPriceIdStr, platformId );
      }
    },


    /**
     * 检查是否允许贷款
     */
    async clickFinalMoney( goodsId, spPriceIdStr, platformId ) {

      /***
       * 获取规格的贷款信息
       * {
       *  //最低贷款比例
       *  "downPaymentRatio" : 10,
       *  "umoneyProjectId": "123",
       *  //最低贷款金额
       *  "priceLimit": 100,
       *  "methoidInterestPayment": 2
       * }
       */
      try {
          let parmas = {
             //商品id
            goodsId,
            //规格的价格id
            spPriceIdStr,  
            //贷款平台
            platformId
          }
          let { data={} } = await this.$fetch('third_getfindLoanInfoBySpPriceId', parmas);
          //贷款信息
          this.loanInfo = Object.assign( {}, data )
          this.calLoanPriceScope( this.loanInfo );
          
      } catch (error) {
          this.$message.error("贷款信息获取失败，无法贷款，请联系管理员")
          //如果获取不到信息，则不允许贷款
          this.showloanCheck = false
      }
      
    },

    // 计算最低，最高的贷款金额
      calLoanPriceScope( loanInfo ){
        let minFirstPayPrice = this.shouldPay *  loanInfo.downPaymentRatio/100.0 
        if( isNaN(minFirstPayPrice) ){
          minFirstPayPrice = 0;
        }
        this.minFirstPayPrice = minFirstPayPrice.toFixed(2)
        if( this.collectionForm.preDownPayment == 0 ){
            this.collectionForm.preDownPayment = this.minFirstPayPrice;
        }
        let maxFirstPayPrice = this.shouldPay - loanInfo.priceLimit
        if( isNaN(maxFirstPayPrice)  || maxFirstPayPrice < 0){
          maxFirstPayPrice = 0;
        }
        if( maxFirstPayPrice <= 0 && this.showloanCheck ){
          this.showloadfont = true;
          this.collectionForm.isLoan==1
        }else if( this.showloanCheck ){
          this.showloadfont = false;
        }
        this.maxFirstPayPrice = maxFirstPayPrice.toFixed(2)
      },
      
      /***
       * 首付款金额的取消焦点事件
       */
      endFirstPartPayChange(){

        let minFirstPayPrice = Number(this.minFirstPayPrice) 
        if( isNaN(minFirstPayPrice)  || minFirstPayPrice < 0 ){
          minFirstPayPrice = 0;
        }
        if( this.collectionForm.preDownPayment <  minFirstPayPrice ){
          this.$message.error(`最低首付不能低于${ (minFirstPayPrice).toFixed(2)}`);
          this.initPreDownPayment();
          return;
        }
      },

      /***
       * 首付款金额的输入事件
       */
      onFirstPartPayChange(val){
        
      //保证小数点不能超过2位  
      if( /\.[\d.]{3,}$/.test(`${val}`) ){
         this.$message.error("输入金额仅支持两位小数");
         this.initPreDownPayment();
         return;
       }  

        let value = Number(val);
        //输入的不是数字，将首付款清零
        if( isNaN(value) ){
          this.$message.error("只能输入数字");
          this.initPreDownPayment();
          return;
        }

        //2、不能超过最高可贷金额
        let maxPrice = this.shouldPay - this.loanInfo.priceLimit
        if( value > maxPrice ){
          this.$message.error(`首付款不能高于${maxPrice}`);
          this.initPreDownPayment();
          return;
        }
        this.calcNeedPay();
      },

      initPreDownPayment(){
        this.collectionForm.preDownPayment = ""
        this.needPay = "" //this.collectionForm.balancePay - this.collectionForm.preDownPayment > 0 ? 0 : this.collectionForm.preDownPayment;   //this.loadPay - (this.balancePay || 0);
      },

      //计算贷款下的应付金额
      calcNeedPay(){     
        let alreadyPay = addPriceFixed( this.collectionForm.balancePay, this.collectionForm.amountReceived );     
        let needPay = minusPriceFixed( alreadyPay, this.collectionForm.preDownPayment ); 
        this.needPay = needPay > 0 ? 0 : Math.abs(needPay);   //this.loadPay - (this.balancePay || 0);
      }, 

      /***
       * 获取线下收款方式
       */
      async findSettlementMethod(schoolNcId){
        let params = {
          schoolId: schoolNcId
        }
        let {data} = await this.$fetch("findSettlementMethod", params);
        // 判断是否是自营三校区
        console.log('data-----------', this.$route.query.property, data );
        let proerTyList = []
        if (this.$route.query.property == 4) {
          data.map(item => {
            if (item.name !== '内部结算') {
              proerTyList.push(item)
            }
          })

          console.log('proerTyList', proerTyList);
        } else {
          proerTyList = data
        }
        this.collectionTypeList = proerTyList || []
        console.log('this.collectionTypeList', this.collectionTypeList);
        return this.collectionTypeList;
      }, 
      /***
       * 获取线上收款方式
       */
       async findSettlementMethod_online(schoolNcId){
        let params = {
          schoolId: schoolNcId,
          payType: 0
        }
        let {data} = await this.$fetch("findSettlementMethod", params);
        if(data[0]){
          this.isShowYuE = data[0].payMode
        }
        console.log('dddddddddddddddddddddddddddddddddddd', data );
      }, 

      /***
       * 银行账号  mengze todo
       */
      async getBackAccountList( schoolId ){
        let params = { schoolId, type: 0 }
        let {data} =  await this.$fetch("findFundMethod", params);
        return this.backAccountList = data || [];
      },

      /**
       * 贷款方式
       */
      async findLoanPlatform(){
        let {data} = await this.$fetch("findLoanPlatform");
        return this.loanPlatformList = data || [];
      },

      /***
       * 成功上传图片的回调函数。
       * 属于一个高级函数，需要保存是多个上传图片控件的某一个上传的。
       */
      handleAvatarSuccess( index ) {
        return ( _, file )=>{
          let item = this.voucherList[index];
          item.isUploadSuccess = true; //已经上传成功
          item.scaleImgUrl = URL.createObjectURL(file.raw);
          
        }
      },
      /***
       * 图片上传前的检查；
       * 只允许这四种类型的图片上传： ["jpg", "jpeg", "bmp", "png"]
       */
      beforeAvatarUpload( index ){
        return async ( file )=>{
          let allowExt = ["jpg", "jpeg", "bmp", "png"];
          let isAllowUpload = allowExt.some( item=>{
              return file.type.indexOf( item ) > -1;
          })

          if (!isAllowUpload) {
            this.$message.error('上传凭证只允许 jpg、jpeg、bmp、png 格式!');
          }

          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            isAllowUpload = false;
          }
          let len = file.name.split('.')
          const time = formatTimesFileName(new Date()).replace(/-/g, '')
          const random = this.getRandomNum(10000, 99999)
          this.imgUpload.name = `${time}${random}.${len[len.length-1]}`
          return isAllowUpload
        }
      },
      uploadRequest(index) {
        return (op) => {
          this.myupload(
            op.file,
            index,
            res => {
              let temp = {
                name: res.attachment,
                url: res.aliyunAddress
              }
              // 成功后的回调，把结果返回，并且把上传列表的状态改变，打上成功的√
              op.onSuccess(temp)
            },
            err => {
              console.log(err)
            },
            res => {
            // 处理进度条的东西，把进度数据传进去实现进度条走动，传参res应该是这样的类型{percent: 48}
              op.onProgress(res)
            }
          )
        }
      },
      myupload(file, index, successCallback = new Function(), errCallBack = new Function(), progressCallback = new Function()) {
      let item = this.voucherList[index];
      let fileName = file.name
      this.$fetch("ossSigniture", {code: 'tianyi'})
      .then(res => {
        let obj = res.data
        let config = {}
        config.host = obj['host']
        config.policyBase64 = obj['policy']
        config.accessid = obj['accessId']
        config.signature = obj['signature']
        config.expire = parseInt(obj['expire'])
        config.callbackbody = obj['callback']
        config.dir = obj['dir']
        this.imgUpload.dir = obj.dir

        const random = this.getRandomNum(10000, 99999)
        const len = file.name.split('.')
        const time = formatTimesFileName(new Date()).replace(/-/g, '')
        const newName = `${time}${random}.${len[len.length-1]}`
        const renameFile = new File([file], newName, {type: file.type, uid: file.uid})
        // this.realFileList.push({uid: file.uid, name: newName})
        let form = new FormData(),
        key = config.dir + md5((this.$route.query.orderId || '') + '' + Date.now(), 8).substr(0, 10) + '_'
        form.append('name', newName)
        form.append('key', `${obj.dir}\${filename}`)
        form.append('policy', obj.policy)
        form.append('OSSAccessKeyId', obj.accessid)
        form.append('expire', obj.expire)
        form.append('host', obj.host)
        form.append('success_action_status', 200)
        form.append('signature', obj.signature)
        form.append('file', renameFile)
        if (config.host.indexOf('http:') > -1) {
          var protocol = window.location.protocol || 'http:'
          var subUrl = config.host.substring(5, config.host.length)
          config.host = protocol + subUrl
        }
        axios({
          url: config.host,
          method: 'POST',
          data: form,
          processData: false,
          cache: false,
          contentType: false,
          onUploadProgress: function(progressEvent) {
            let host = "https://hq-tianyi.hqjy.com";
            // let photoUrl = `${host}/${this.imgUpload.dir}${this.imgUpload.name}`
            let photoUrl = `${host}/${config.dir}${newName}`
            item.photoUrl = process.env.VUE_APP_BASE_URL + "/mz_proxy?url="+encodeURI(photoUrl);
            console.log(`item.photoUrl: ${item.photoUrl}`)
            if (progressEvent.lengthComputable) {
              let percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
              progressCallback({ percent: percent })
            }
          }
        })
          .then(() => {
            let size = file.size > 1000000 ? parseFloat(file.size / 1000000).toFixed(2) + 'M' : parseFloat(file.size / 1000).toFixed(2) + 'KB'
            successCallback({
              attachment: fileName,
              aliyunAddress: key,
              size: size
            })
          })
          .catch(err => {
            errCallBack(err)
          })
      })
      .catch(err => {
        errCallBack(err)
        // console.log(err)
      })
    },
      getRandomNum(Min,Max) {
        let Range = Max - Min;
        let Rand = Math.random();
        return(Min + Math.round(Rand * Range));
      }
  },


  beforeDestroy() {
    this.clearTimer()
  },
  destroyed(){
    this.clearTimer();
  },

}
</script>


<style lang="scss" scoped>

  ::v-deep img {
    object-fit: contain;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 36px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border: 1px solid #eee;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .collection-form {
    padding-top: 12px;
    padding-bottom: 200px;
    .page-width {
      width: 94%;
      margin: 0 auto;

      >span {
        font-size: 14px;
        color: #333;
        display: block;
        margin-bottom: 22px;
      }
    }

    h2 {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
    .litter-title {
      color: #999;
      font-size: 14px;
    }

    .voucher-list {
      border: 1px solid #F0F0F3;
      margin: 12px 0!important;
      padding-top: 10px;
      width: 60%;
      position: relative;

      .deleteBtn {
        position: absolute;
        top: 0;
        right: 0;
        color: red!important;
      }
    }

    .upload-box {
      width: 100%;
      height: 168px;
      border: 1px solid #F0F0F3;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &::before {
        position: absolute;
        content: "凭证图片";
        left: 10px;
        top: -10px;
        font-size: 12px;
        color: #1976d2;
      }

      .upload-btn {
        width: 60px;
        height: 60px;
        border: 1px solid #999;
        position: relative;
        cursor: pointer;

        &::before {
          position: absolute;
          content: "";
          width: 4px;
          height: 36px;
          background: #999;
          left: 28px;
          top: 12px;
        }
        &::after {
          position: absolute;
          content: "";
          height: 4px;
          width: 36px;
          background: #999;
          left: 12px;
          top: 28px;
        }
      }

      .img-box {
        width: 94%;
        height: 96%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .footer {
      width: 100%;
      height: 90px;
      line-height: 90px;
      text-align: center;
    }
  }





::v-deep .el-radio__input {
  display: none;
}

::v-deep .el-radio {
  margin-right: 10px;
  margin-left: 10px;
}

.form-wrapper {
  // width: 1508px;
  // margin:auto;
  padding-top: 12px;
  .title {
    text-align: center;
    // font-weight: bold;
    font-size: 18px;
    line-height: 80px;
    color: #182d42;
  }
}

.collection {
  // width: 94%;
  // margin: 0 auto;
  padding-bottom: 36px;
  background-color: #fff;
  .breadcrumb-wrapper {
    padding-left: 18px;
    margin-bottom: 18px;
  }
  .section-wrapper {
    margin-bottom: 24px;
  }
  .baseinfo-wrapper {
    .baseinfo-title {
      margin-bottom: 18px;
      font-size: 16px;
      font-weight: 700;
      color: #333;
    }
    .baseinfo-content {
      margin-top: 25px;
      .el-form-item {
        // background:#F9FAFC;
        // border: 1px solid #DFDFE6;
        box-sizing: border-box;
        padding:0 18px;
        position: relative;
        box-sizing: border-box;
        height: 42px;
        ::v-deep .el-form-item__label {
          position: absolute;
          left:0;  
          top:0;
          color:#999;
          background:#fff;
          padding:4px 6px;
          line-height:1;
          width: auto !important;;
          margin-left: 6px;
          margin-top: -14px;
        }
      }

      // border: 1px solid red;
      // width: 720px;
    }
  }
  .paymode-wrapper {
    .paymode-title {
      margin-bottom: 18px;
      font-size: 16px;
      font-weight: 700;
      color: #333;
    }
    .paymode-content {
      min-height: auto;
      padding-left: 20px;
      margin-bottom: 20px;
      .tips {
        padding-top: 14px;
        padding-left: 50px;
        font-size: 14px;
        // color: $primary_color;
      }
      .el-form-item {
        margin-bottom: 12px;
      }
      .payWay-radio:first-child {
        margin-right: 54px;
      }
      ::v-deep .el-radio {
        &.is-checked {
          border:1px solid #337AFF;
          background: #E5F2FF url('~@/assets/image/select-ok-normal.png') no-repeat top right;
          background-size: 30px;
          .el-radio__label {
            color:#333;
          }
        }
        border-radius: 0;
        color:#333;
      }
    }
  }
  .paytip-wrapper {
    display: flex;
    color:#337AFF;
    font-size: 14px;
    line-height: 1.6;
    width: 604px;
    >h2 {
      flex:none;
      width: 50px;
    }
    >div {
      flex:1;
      >div {
        display: flex;
        flex-wrap: nowrap;
        >p {
          white-space: nowrap;
          margin-bottom: 10px;
        }
        span {
          font-weight: bold;  
        }
      }
    }
  }
  .bottom-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 90px;
    .el-button {
      border-radius:0;
      height: 42px;
      margin-left: 18px;
    }
  }
}
.hr {
  width: 100%;
  overflow:hidden;
  &:before {
    content: '';
    display:block;
    margin: 20px 0;
    padding: 0 18px;
    border-top: 1px dashed #e1e4eb;
    transform: scaleX(2.5);
  }
}

.tip-enter,
.tip-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.tip-enter-active,
.tip-leave-active {
  transition: 0.3s;
}

.page-width {
    width: 94%;
    margin: 0 auto;

    >span {
      font-size: 14px;
      color: #333;
    }
  }

  h2 {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .memo-form {
    width: 100%;

    ::v-deep .el-form-item__content {
      width: 100%;
    }
  }

  .pz-btn {
    line-height: 28px;
    display: inline-block;
    padding: 0 8px;
    border: 1px solid #3a8ee6;
    background: #3a8ee6;
    color: #fff;
    cursor: pointer;
  }

</style>
