<template>
  <div class="customer">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
            <div></div>
            
            <div style="line-height: 40px">
              <NcChangeModal v-if="permUtil.WbOrder.orderNcUpdateteacher()" :method="getNCOrderList" :disabled="currCol.status != 1" title="招生" :currCol="currCol" :teacherList="teacherList" />
              <NcExport v-if="permUtil.WbOrder.orderNcExport()" :pageParam="pageParam" :total="total" :searchParam="searchParam" />
            </div>
        </div>
      </div>
      <div
        :gutter="10"
        style="width: 94%; margin: 0 auto"
      >
        <Search
          v-if="searchParamList.length > 0"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="collectionList"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @row-click="clickRow"
        >
          <el-table-column :show-overflow-tooltip="true" v-for="(item, index) in columns" :key="index"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            :fixed="!!item.fixed">
            <template slot-scope="scope">
              <div v-if="item.prop !== 'phone'">{{ scope.row[item.prop] | empty }}</div>
              <div v-else>
                <span>{{ scope.row.phone }}</span>
                <el-button @click="seeShowClick(scope.row)" type="text" icon="el-icon-document-copy"></el-button>
              </div>
            </template>
					</el-table-column>
          <el-table-column
            label="操作"
            prop=""
            width="230"
            fixed="right"
            class-name="action_col"
          >
            <template slot-scope="scope">
							<div style="display:flex" v-if="scope.row.operation">
                <el-button type="primary" class="action_btn" :disabled="!scope.row.refund" @click="toAction(scope.row, aftersaleTypeEnum.dropout)">退费</el-button>  
                <el-button type="primary" class="action_btn" :disabled="!scope.row.promotion" @click="toAction(scope.row, aftersaleTypeEnum.upgrade)">升班</el-button>  
                <el-button type="primary" class="action_btn" :disabled="!scope.row.transfer" @click="toAction(scope.row, aftersaleTypeEnum.transfer)">转班</el-button>  
							</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Search from "components/search";
import NcExport from "./nc-export";
import NcChangeModal from "./nc-change-modal";
import { getNcOrderDetail, getNCOrderList,getNCsaveOperation } from "api/order/change";
import { aftersaleTypeEnum } from '../constants';
import { mapGetters } from 'vuex';
import { getRecruitSpinner, getDeptList, getUserCustomQuery, getNcUserList, getTeacherListToNc, doUpdateTeacher } from "api/public";

export default {
  name: "orderList",
  components: {
    Search,
    NcExport,
    NcChangeModal
  },
  data() {
    const searchParamList = ["studentName", "phone", 'orderNo', 'itemName', 'orderType', 'isClassType', 'education', 'idCard', 'studentNames', 'pkSupplier', 'beginTime']
    return {
      tableData: [],
      listLoading: false,
      searchAllParam: [
        { name: "studentName", label: "学员姓名", type: "input", value: ""},
        { name: "phone", label: "手机", type: "input", value: "" },
        { name: "orderNo", label: "报名号", type: "input", value: "" },
        { name: "itemName", label: "报读班型", type: "input", value: "" },
        {
          name: "orderType",
          label: "报名单类型",
          type: "dictMult",
          code: "NC_ORDER_TYPE",
          value: [],
        },
        {
          name: "isClassType",
          label: "学历班型",
          type: "dict",
          code: "IS_CLASS_TYPE",
          value: "",
        },
        {
          name: "education",
          label: "学员学历",
          type: "dictMult",
          code: "EDUCATION_TYPE",
          value: "",
        },
        { name: "idCard", label: "身份证", type: "input", value: ""},
        {
          name: "beginTime",
          label: "日期",
          type: "date",
          ref: "follow",
          show: false,
          value: [],
          // value: [formatTimesHour(getDate()), formatTimesHour(new Date())],
        },
        {
          name: "pkSupplier",
          label: "渠道名称",
          type: "allSelect",
          option: [],
          value: "",
          text: "name", //label
          id: "pkSupplier"   //value
        },
        {
          name: "dealDeptIdList",
          label: "成交校区",
          type: "multiTree",
          option: [],
          value: "",
        },
        {
          name: "isRecommand",
          label: "转介绍",
          type: "select",
          option: [{
            id: 1,
            name: '是'
          }, {
            id: 0,
            name: '否'
          }],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "teacherRecruitCrmPk",
          label: "招生老师",
          type: "select",
          option: [],
          value: "",
          text: "name", //label
          id: "ncUserPk"   //value
        },
      ],
      columns: [
        {label: '报名号', prop: 'orderNo', width: 180, fixed: true},
        {label: '报名表标识状态', prop: 'flagStatusName', width: 150},
        {label: '报读班型', prop: 'itemName', width: 150},
        {label: '学历班型', prop: 'isEducationName', width: 100},
        {label: '状态', prop: 'statusName', width: 100},
        {label: '升班', prop: 'isUpdateItemName', width: 80},
        {label: '学员名称', prop: 'studentName', width: 150},
        {label: '学员学历', prop: 'educationName', width: 100},
        {label: '电话', prop: 'phone', width: 150},
        {label: '成交校区', prop: 'dealSchoolName', width: 150},
        {label: '上课校区', prop: 'schoolName', width: 150},
        {label: '招生老师', prop: 'teacherName', width: 150},
        {label: '销售老师', prop: 'salesManName', width: 150},
        {label: '单据状态', prop: 'orderStatusName', width: 150},
        {label: '渠道名称', prop: 'admissionsWayName', width: 150},
        {label: '报名单类型', prop: 'orderType', width: 150},
        {label: '结算金额合计', prop: 'money', width: 150},
        {label: '身份证', prop: 'idCard', width: 150},
        {label: '制单时间', prop: 'signUpTime', width: 150},
      ],
      searchParamList,
      defaultParamList: searchParamList,
      searchParamFromApi:[],
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        sortItems: [
          {
            asc: false,
            column: "signUpTime",
          },
        ],
      },
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0,
			aftersaleTypeEnum,
      currCol: {},
      // 招生老师
      teacherList: []
    };  
  },
  computed: {
    ...mapGetters(['loginUser']),
    tableHeight() {
      return window.outerHeight - this.topHeight - 215 + 'px'
    }
  },
  mounted() {
    this.getSearchList();
    this.getNCOrderList();
    this._getSearchAll()
  },
  methods: {
    // 复制号码
    seeShowClick(v) {
      console.log('v', v);
      let _this = this
      if (!v.seeShow) {
        let params = {
          studentPk: v.studentPk,
          detail: v.newPhone,
          operationKey: "getDetailByNcOrder",
          operationType: 6
        }
        getNCsaveOperation(params).then(res => {
          if (res.code == 0) {
            v.oldPhone = v.phone
            v.phone = v.newPhone
            v.seeShow = !v.seeShow
          }
          this.$forceUpdate()
          this.$copyText(v.phone).then(function () {
            _this.$message.success("已复制号码")
          }, function () {
            _this.$message.error("抱歉，复制号码失败")
          })
        })
      } else {
        v.phone = v.oldPhone
        delete v.oldPhone
        v.seeShow = !v.seeShow
        this.$forceUpdate()
      }
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList()
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 40
      })
    },
    // 获取一开始的下拉框来源
    async _getSearchAll() {
      this.searchAllParam.map((item) => {
        if(item.name === "pkSupplier") {
          getRecruitSpinner().then(data => {
            item.option = data.result
          })
        } else if (item.name == 'dealDeptIdList') {
          getDeptList().then(res => item.option = res.result)
        } else if(item.name == 'teacherRecruitCrmPk') {
          getNcUserList().then(res => item.option = res.result)
        }
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const {code, result } = await getUserCustomQuery('collectionList');

      if(code === 0) {
        this.defaultParamList = ["studentName", "phone", "orderNo"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          
          this.searchParamFromApi = JSON.parse(result.fieldData)
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        console.log('this.searchParamList---------', this.searchParamList);
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 44
        })
      }
    },
    // 获取列表数据
    async getNCOrderList() {
      this.listLoading = true;
      let form = {
        ...this.searchParam,
        ...this.pageParam
      }
      if(!form.studentName) form.studentName = ''
      if(!form.phone) form.phone = ''
      if(!form.orderNo) form.orderNo = ''
      if(!form.itemName) form.itemName = ''
      const { code, result, msg } = await getNCOrderList(form)
      if( code == 0 ){
        //1、更新分页数据
        result.list.map(item => {
          if(item.phone) {
            item.seeShow = false
          }
        })
        //2、更新列表页面
        this.tableData  = result.list || [];
        this.total = result.total
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      }else{
        this.$message.error(msg)
      }
   
      this.listLoading = false;
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getNCOrderList()
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getNCOrderList()
    },
    // 单选事件
    clickRow(row) {
      this.currCol = row;
      getTeacherListToNc([row.dealSchoolId, row.schoolId]).then(res => {
        if (res.code == 0) {
          this.teacherList = res.result || []
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查询条件
    search() {
      this.searchParam = {}
      this.searchAllParam.forEach( item => {
        if(item.type === "date") {
          if (item.name === "beginTime") {
            this.searchParam.beginCreateTime =
              item.value && item.value.length ? item.value[0] : "";
            this.searchParam.endCreateTime =
              item.value && item.value.length ? item.value[1] : "";
          }
        }
          if( (item.value !== undefined && item.value !== null && item.value !== '' ) || ( Array.isArray(item.value)  && item.value.length ) ) {
            this.searchParam[item.name] = item.value 
                this.searchParam[item.name] = item.value 
            this.searchParam[item.name] = item.value 
         }
      })

      this.getNCOrderList()
    },
    // 重置
    reset() {
      this.searchParamList.map(item => {
        if(item.value || item.value == 0) {
          item.value = ""
        }
      })

      this.pageParam = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        sortItems: [
          {
            asc: false,
            column: "signUpTime",
          },
        ],
      }
      
      this.search()
    },
    /**
     * 跳转到报名页面
     */
    async toAction(data, aftersaleType){
      console.log(data, aftersaleType);
      let transferSchool = data.dealSchoolName == data.schoolName ? 0 : 1
      if(!data.orderNo) return
      const {code, msg} = await getNcOrderDetail({orderNo: data.orderNo})
      if(code !== 0){
        msg && this.$message.error(msg)
        return
      }
      this.$router.push({
        name: aftersaleTypeEnum.dropout === aftersaleType ? 'ncDropout' :  aftersaleTypeEnum.upgrade === aftersaleType ? 'ncUpgrade' : 'ncTransfer',
        query: {
          orderNo: data.orderNo,
          transferSchool
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.customer {
  // padding-bottom: 40px;
  .titie {
    text-align: left;
  }

  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;

    .tab-bar {
      overflow: hidden;

      .active {
        color: #409eff;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #337aff;
          bottom: 0;
          left: 0;
        }
      }

      > span {
        float: left;
        margin-right: 12px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
  .phone {
    .el-button--text {
      padding: 10px 4px;
    }
  }
}
::v-deep .action_col{
  padding: 4px 0 !important;
}
.action_btn{
  width: 60px;
  padding: 0;
  height: 24px;
  line-height: 24px;
  background-color: #337AFF;
  font-size: 13px;
  &.is-disabled{
    background-color: #E1E4EB;
    border-color: #E1E4EB;
  }
}
</style>
